<template>
    <v-row>
        <v-col>
            <v-tabs v-model="tab" centered dark icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    Join
                    <v-icon>mdi-playlist-plus</v-icon>
                </v-tab>

                <v-tab>
                    Create
                    <v-icon>mdi-pencil-plus-outline</v-icon>
                </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item :key="0">
                    <v-container>
                        <v-card flat>
                            <v-card-text>
                                <v-form>
                                    <v-row class="justify-center">
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                        <v-col class="d-flex justify-center align-center">
                                            <v-text-field v-model="leagueCode" label="League Code"
                                                hint="Code from league organiser" persistent-hint>
                                                <template v-slot:append-outer>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs"
                                                                v-on="on">mdi-information-outline</v-icon>
                                                        </template>
                                                        <span>Get the league code from the league admin</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                            <v-btn class="secondary ml-4"
                                                @click="registerUser({ 'leagueCode': leagueCode, 'userId': loggedInUser.userDetails.id })">Join</v-btn>
                                        </v-col>
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />

                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-tab-item>
                <v-tab-item :key="2">
                    <v-container>
                        <v-card flat>
                            <v-card-text>
                                <v-form>
                                    <v-row class="justify-center">
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                        <v-col class="d-flex justify-center align-center">
                                            <v-text-field v-model="leagueName" label="League Name"
                                                @input="search" :loading="loading">
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="d-flex justify-center align-center">
                                            <v-select disabled v-model="gameweekStart" persistent-hint
                                                :items="gameweeks" label="Gameweek Start">
                                                <template v-slot:append-outer>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs"
                                                                v-on="on">mdi-information-outline</v-icon>
                                                        </template>
                                                        <span>Choose which gameweeks to include, e.g. if you choose '2',
                                                            the league only
                                                            includes points from week 2 onwards</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                    </v-row>
                                    <v-row class="justify-center py-0">
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                        <v-col class="d-flex justify-center align-center py-0">
                                            <v-text-field flat solo readonly hide-details value="Appear in search">
                                            </v-text-field>
                                            <v-switch v-model="searchable">
                                                <template v-slot:append>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs"
                                                                v-on="on">mdi-information-outline</v-icon>
                                                        </template>
                                                        <span>League can be found and viewed via search. <br />You
                                                            cannot make a league entirely
                                                            hidden, simply prevent it being searchable
                                                        </span>
                                                    </v-tooltip>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                    </v-row>
                                    <v-row class="justify-center py-0">
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                        <v-col class="d-flex justify-center align-center py-0">
                                            <v-text-field flat solo readonly hide-details value="Show code">
                                            </v-text-field>
                                            <v-switch v-model="showCode">
                                                <template v-slot:append>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs"
                                                                v-on="on">mdi-information-outline</v-icon>
                                                        </template>
                                                        <span>Code to join the league is visible on the league page to
                                                            non league members</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />
                                    </v-row>
                                    <v-row>
                                        <v-col class="d-flex justify-center align-center">
                                            <v-btn class="secondary ml-4" :disabled="!isValidName"
                                                @click="createPrivateLeague(
                                                    { 'name': leagueName, 
                                                    'userId': loggedInUser.userDetails.id,
                                                    'searchable': searchable,
                                                    'showCode': showCode
                                                    })">
                                                Create</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: ['mode'],
    data() {
        return {
            tab: 0,
            leagueCode: null,
            leagueName: '',
            gameweekStart: 'First',
            searchable: true,
            showCode: true,
            loading: false
        }
    },
    computed: {
        ...mapGetters(["loggedInUser", "isValidName"]),
        gameweeks() {
            return ['First']
        }
    },
    methods: {
        ...mapActions(["createPrivateLeague", "registerUser", "existsByLeagueName"]),
        async search() {
            if (this.leagueName && this.leagueName !== this.select) {
                if (this.leagueName.length > 2) {
                    this.loading = true
                    let response = await this.existsByLeagueName(this.leagueName);
                    this.loading = false;
                    this.items = response.data;
                }
            }
        },
        mounted() {
            if (this.mode == 'create') {
                this.tab = 1;
            }
        }
    }
}
</script>